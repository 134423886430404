:root {
  --main-bg: white;
  /* --main-bg: #c4c2b5; */
  --dark-clr: #332e34;
  --white-clr: rgb(252, 252, 252);
  --accent-clr: #5f0310;
  --action-clr: yellow;
  --dark-grey: rgb(116, 116, 116);
  --fs-big: 3rem;
  --fs-medium: 1.2rem;
  --fs-normal: 1rem;
  --fs-small: 0.9rem;
  --fs-extra-small: 0.8rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/* mix-blend-mode: difference; */
html,
body {
  background-color: var(--main-bg);
  position: relative;
  scroll-behavior: smooth;
}
img {
  width: 100%;
}
ul {
  padding-left: 0 !important;
}
body {
  /* overscroll-behavior-x: none; */
  margin: 0;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}
#root {
  /* overflow-x: hidden; */
  /* scroll-behavior: smooth; */
}
h1 {
  font-size: var(--fs-big);
  text-align: center;
  /* color: var(--accent-clr); */
}
iframe {
  display: block;
  border: none;
}

/* GENERIC CLASSES */
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-flex {
  display: flex;
}

.w100 {
  width: 100%;
}
.small-container {
  max-width: 600px;
  margin-inline: auto;
  padding-inline: 1rem;
}
.large-container {
  padding-inline: 1rem;
}

/* LANDING PAGE */
/* -------------- */
.hero {
  background-color: rgba(0, 0, 0, 0.699);
  height: 100vh;
  height: 100svh;
  position: relative;
  isolation: isolate;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('./assets/fried.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10rem;
  padding-inline: 2rem;
  gap: 2rem;
  color: var(--white-clr);
  text-align: center;
}
.hero p {
  width: 70%;
}
.page-header {
  padding-top: 120px;
}
.menu-home {
  background-color: rgba(0, 0, 0, 0.699);
  height: 100vh;
  height: 100svh;
  position: relative;
  isolation: isolate;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url('./assets/transaparent-st-chicken.png');
  background-size: 93px;
}
.landing-product-container {
  display: grid;
  grid-template-rows: 7fr 3fr;
  height: 100%;
}
.lading-image-contaier {
  position: relative;
  display: block;
}
.landing-img {
  position: absolute;
  z-index: -2;
  inset: 0;
  background-repeat: no-repeat;
  background-size: 250%;
  /* animation: imageHover 15s linear infinite; */
}
@keyframes imageHover {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.black-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -1;
}
.landing-product-details {
  z-index: 3;
  position: relative;
}
.feature-navigate {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 50%;
  padding: 0 0.5rem;
}
.feature-navigate-button {
  border: none;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.103);
  padding: 0 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 2rem;
}
.feature-navigate-button:hover,
.feature-navigate-button:focus {
  color: white;
  background: rgba(0, 0, 0, 0.203);
}
.feature-navigate-button:focus {
  outline: 1px solid black;
}
.menu-link {
  font-size: var(--fs-normal);
  text-decoration: none;
  color: white;
  padding: 0.4rem 1rem;
  background-color: var(--accent-clr);
  border-radius: 10px;
  -webkit-box-align: center;
}
.romb-shape {
  margin-right: 5px;
  margin-bottom: 0.2em;
  margin-left: 5px;
  font-size: 0.6em;
}
.landing-scroll {
  margin-top: auto;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  color: white;
  animation: up-down 2000ms ease-in-out infinite;
}
.landing-product h2 {
  align-self: flex-start;
  font-size: 1.6rem;
}
.landing-product p {
  font-size: var(--fs-small);
  line-height: 1.4;
}
.landing-product {
  padding: 1rem;
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.landing-price {
  background-color: var(--accent-clr);
  color: white;
  width: 60px;
  aspect-ratio: 1;
  vertical-align: center;
  padding: 0.7rem;
  border-radius: 50%;
  position: absolute;
  font-size: 1.2rem;
  top: 0;
  right: 0;
  transform: translate(-20%, -50%);
}
@keyframes up-down {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(30px);
  }
}
/* ------------------ */
/* FOOTER */
.footer-container {
  position: relative;
  isolation: isolate;
  /* padding-top: 2rem; */
  background-color: var(--accent-clr);
}
footer {
  padding: 3rem 1rem 1rem 1rem;
  color: var(--white-clr);
  font-size: 15px;
  position: relative;
}
footer nav a {
  color: white;
  font-size: var(--fs-extra-small);
  text-decoration: none;
}
.footer-nav-container {
  max-width: 500px;
  margin-inline: auto;
}
footer nav {
  padding: 1rem;
  margin-top: 2rem;
}
.logo-social-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  max-width: 500px;
  margin-inline: auto;
}
.footer-info {
  font-size: var(--fs-small);
  margin-inline: auto;
  width: 100%;
}
.footer-info i {
  margin-right: 0.5rem;
}
.footer-logo {
  width: 200px;
  height: 200px;
  pointer-events: none;
  position: absolute;
  opacity: 0.2;
  z-index: -1;
  right: 0;
  top: 0;
}
.social-container {
  gap: 10px;
}
.social-container h4 {
  text-align: center;
  margin-bottom: 0.6rem;
  color: #f1372e;
  display: none;
}
.social {
  gap: 10px;
}
.footer-social {
  width: 25px;
}
.map {
  width: 100%;
  height: 300px;
  /* filter: grayscale(1); */
  /* transition: all 1s linear; */
}
/* .map:hover,
.map:focus {
  filter: grayscale(0);
} */
/* ---------------- */
header {
  opacity: 1;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: opacity 300ms ease-in;
  /* pointer-events: none; */
  z-index: 99;
}
.bg {
  /* opacity: 1; */
  /* pointer-events: auto; */
  background: #00000073;
}
.bg-menu {
  background: var(--white-clr);
}
nav {
  padding: 1.2rem;
  padding-inline: 1.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav ul li {
  font-size: var(--fs-extra-small);
}

nav ul {
  clip-path: circle(100% at 50% 50%);
  flex-direction: row;
  position: relative;
  height: auto;
}
.menu-page {
  background: linear-gradient(
      rgba(255, 255, 255, 0.95),
      rgba(255, 255, 255, 0.95)
    ),
    url('./assets/test.png');
  background-size: 93px;
}
.main-menu {
  max-width: 1200px;
  margin-inline: auto;
  padding: 60px 1rem 100px 1rem;
  /* padding-top: 120px; */
}

.menuList {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  list-style: none;
  gap: 0.5rem;
  padding-top: 20vh;
  clip-path: circle(0% at 50% 50%);
  transition: clip-path 500ms;
  background-color: white;
  background: linear-gradient(
      rgba(255, 255, 255, 0.85),
      rgba(255, 255, 255, 0.85)
    ),
    url('./assets/menu-bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* pointer-events: none; */
}
.menuList.open {
  clip-path: circle(100% at 50% 50%);
}
.menuList a {
  text-decoration: none;
  font-size: 30px;
  color: var(--accent-clr);
}
.menuList .active {
  color: red;
}

.open-menu {
  z-index: 100;
  width: 40px;
  height: 40px;
  padding-block: 5px;
  justify-content: space-between;
  background: none;
  border: none;
}

.line {
  height: 3px;
  background-color: var(--white-clr);
  border-radius: 5px;
  transition: all 300ms cubic-bezier(0, -1.02, 1, 1.66);
}
.line:nth-child(1) {
  transform-origin: right;
  width: 60%;
  align-self: end;
}
.line:nth-child(2) {
  transform-origin: center;
}
.line:nth-child(3) {
  transform-origin: left;
  width: 60%;
  align-self: start;
}

/* .open-menu.opened {
  height: 35px;
} */
.open-menu.opened .line:nth-child(1) {
  transform: translate(-5px, -1px) rotate(-45deg);
  background-color: var(--accent-clr);
  width: 50%;
}
.open-menu.opened .line:nth-child(2) {
  transform: rotate(45deg);
  background-color: var(--accent-clr);
}
.open-menu.opened .line:nth-child(3) {
  transform: translate(6px, 1px) rotate(-45deg);
  background-color: var(--accent-clr);
  width: 50%;
}

.logoContainer {
  position: relative;
  width: 70px;
  aspect-ratio: 0.8;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  /* height: 100px; */
}
.logo {
  position: absolute;
  width: 130%;
}

.rotate {
  /* z-index: 3; */
  animation: rotate 10s infinite linear;
}
canvas {
  width: 100vw;
  height: 100vh;
}
.main {
  padding: 4rem 2rem 12rem 2rem;
  /* background: url('./assets/smoke.jpg');
  background-size: cover;
  background-position: top right; */
}
/* .navBtn {
  display: none;
  border: none;
  padding: 1rem 1.5rem;
  cursor: pointer;
  color: var(--accent-clr);
  background: none;
  padding: 1rem;
  z-index: 10;
  font-size: 30px;
} */
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Menu */
/* ----- */
.category-title {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: var(--fs-medium);
}
.product-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.product-item {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.product-title {
  width: 80%;
  font-size: var(--fs-normal);
}
.product-title h3 {
  font-size: var(--fs-extra-small);
}
.product-item.small .product-title {
  width: 70%;
}
.description {
  font-size: var(--fs-extra-small);
  color: var(--dark-grey);
  font-style: italic;
}
.product-item-extra {
  list-style: none;
  align-self: end;
  font-size: var(--fs-small);
  font-style: italic;
}
.extras .category-title {
  font-style: italic;
  font-size: var(--fs-small);
  text-align: right;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding-right: 2rem;
}
.extras .product-list {
  gap: 7px;
}
.product-item.small {
  align-items: center;
}

@media (min-width: 450px) {
  .landing-product {
    justify-content: space-evenly;
  }
}

@media (min-width: 800px) {
  .menuList.home a {
    color: var(--white-clr);
  }
  .menuList.home .active {
    color: red;
  }
  .main-menu {
    display: flex;
    gap: 30px;
  }
  .footer-container::after {
    top: -5.9rem;
    height: 6rem;
  }
  .open-menu {
    display: none;
  }
  .menuList {
    position: static;
    /* inset: auto; */
    height: auto;
    width: auto;
    flex-direction: row;
    gap: 3.5rem;
    padding: 0;
    clip-path: none;
    background: none;
    background-color: transparent;
    pointer-events: auto;
  }
  .menuList a {
    color: var(--accent-clr);
    font-size: var(--fs-medium);
  }
  .menuList .active {
    color: red;
  }
  .map {
    height: 400px;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
/* Contact page */
.contact {
  background: url('./assets/smoke2.jpg');
  background-size: cover;
  background-position: top right;
  padding: 10rem 1rem 5rem 1rem;
}
.contact h1 {
  font-size: var(--fs-normal);
  text-align: left;
}

/* homePage */
.container {
  padding-inline: 1rem;
}
.container > p {
  line-height: 1.4;
  margin-bottom: 1rem;
}
.contact-home {
  background: url('./assets/smoke2.jpg');
  background-size: cover;
  background-position: top right;
  padding-block: 8rem 10rem;
}
.contact-details-home {
  margin-top: 1.5rem;
}
.column-2 {
  display: grid;
  grid-template-columns: 130px 1fr;
  margin-bottom: 1rem;
}
.working-hours {
  max-width: 300px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* gap: 0.1rem; */
  font-size: var(--fs-small);
}
.working-hours span {
  width: 60%;
}
.working-hours span:nth-child(1) {
  width: 40%;
}
.details-title {
  font-size: var(--fs-normal);
}
.hero-btn {
  margin-top: auto;
  margin-bottom: 4rem;
  padding: 1rem 2rem;
  background-color: var(--white-clr);
  border: none;
  border-radius: 50px;
  color: var(--accent-clr);
  cursor: pointer;
}
.hero-btn:hover,
.hero-btn:focus {
  background-color: var(--accent-clr);
  color: var(--white-clr);
}
.delivery {
  background-color: var(--accent-clr);
  color: var(--white-clr);
  font-size: 1.6rem;
  text-align: center;
  padding-block: 3rem;
  margin-block: 6rem 10rem;
}
@media (min-width: 756px) {
  :root {
    --fs-big: 3rem;
    --fs-medium: 1.5rem;
    --fs-normal: 1.3rem;
    --fs-small: 1.1rem;
    --fs-extra-small: 1rem;
  }
  .column-2 {
    grid-template-columns: 240px 1fr;
  }
}
@media (min-width: 800px) {
  .contact > div {
    max-width: 800px;
    margin-inline: auto;
  }
  .column-2 {
    grid-template-columns: 3fr 7fr;
  }
  .hero {
    padding-top: 11rem;
  }
  .hero h1 {
    font-size: 3rem;
  }
  .hero p {
    font-size: 1.2rem;
    width: 60%;
  }

  .about {
    margin-block: 10rem;
    max-width: 1000px;
    margin-inline: auto;
  }
  .about h2 {
    font-size: 2rem;
  }
  .menu-home {
    height: 40rem;
  }
  .landing-product-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .landing-img {
    background-size: 200%;
  }
  .landing-product h2 {
    align-self: center;
    margin-bottom: 1.5rem;
    font-size: 2rem;
  }
  .landing-product {
    justify-content: center;
    padding: 2rem 3.5rem;
  }
  .landing-price {
    position: static;
    transform: none;
    background: none;
    color: black;
    margin-bottom: 3rem;
    font-size: 1.5rem;
  }
  .menu-link {
    /* margin-block: auto; */
  }
  .delivery {
    margin-block: 10rem;
  }
  .delivery img {
    width: 30%;
  }
}
@media (min-width: 1150px) {
  .hero {
    padding-top: 14rem;
  }
  .hero h1 {
    font-size: 4.5rem;
  }
  .hero p {
    font-size: 1.5rem;
    width: 700px;
  }
}
@media (min-width: 1200px) {
  .menu-home {
    width: 80%;
    margin-inline: auto;
  }
  .landing-product-container {
    grid-template-columns: 1.2fr 1fr;
    grid-template-rows: 1fr;
  }
  .landing-img {
    background-size: 140%;
  }
}
@media (min-width: 1400px) {
  .landing-img {
    background-size: 120%;
  }
}

.progress-bar {
  position: absolute;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.233);
  height: 10px;
  overflow: hidden;
}
.progress {
  height: 100%;
  background-color: black;
  width: 0;
}
